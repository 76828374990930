// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

const flag = document.querySelector(".top-guideline");
const target = document.querySelector(".js-dial-fixed");

if(flag) {
  var scene_dial = new ScrollMagic.Scene({
    triggerElement: flag,
    triggerHook: 'onLeave',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);
  
  scene_dial.on('enter', ()=>{
  //triggerElementを過ぎたとき
  target.classList.add("is-show");
  document.querySelector(".c-scrollPageTop").classList.add("is-show");
  });
  scene_dial.on('leave', ()=>{
  //triggerElementを抜けたとき
  target.classList.remove("is-show");
  document.querySelector(".c-scrollPageTop").classList.remove("is-show");
  });
}
