// import swiper from "swiper";
// import isMobile from "../../plugins/is-mobile.js";

// if ($(".js-top-about-links").length) {
//   var slider = new swiper(".js-top-about-links", {
//     slidesPerView: 3,
//     freeMode: false,
//     observer: false,
//     allowTouchMove: false,
//     speed: 1200,
//     spaceBetween: 20,
//     breakpoints: {
//       820: {
//         slidesPerView: 1.25,
//         spaceBetween: 20,
//         allowTouchMove: true,
//         centeredSlides: true,
//         loop: true,
//         autoHeight: true,
//         pagination: {
//           el: ".swiper-pagination",
//           clickable: true
//         },
//       },
//     },
//   });
// }
