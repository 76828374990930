import { TweenMax } from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


const $section = document.querySelectorAll(".js-top-render-fade");

let controller = new ScrollMagic.Controller();
for (let i = 0; i < $section.length; i++) {

  let scene = new ScrollMagic.Scene({
    triggerElement: $section[i],
    triggerHook: "onLeave",
    reverse: true,
    offset: -500,
  })
  // .addIndicators()
  .addTo(controller);
  scene.on("enter", () => {
    $($section[i]).addClass("-scroll-in");
  });
  scene.on("end", () => {
    //console.log('end');
    // scene.destroy(true);
  });
}
