import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';

//---------------------------
//datepicker起動
//---------------------------
datepickerFactory($);
datepickerJAFactory($);
$(".js-datepicker").each(function(index) {
  $.datepicker.regional['ja'];
});


//---------------------------
//戻るボタンクリック
//---------------------------
$(".wpcf7c-btn-back").click(function() {
  $('.wpcf7-form').removeClass('custom-wpcf7c-confirmed');
});
