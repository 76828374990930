import swiper from "swiper";
import isMobile from "../../plugins/is-mobile.js";

if ($(".js-hero-slider").length) {
  var slider = new swiper(".js-hero-slider", {
    slidesPerView: "auto",
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    effect: "fade",
    fade: {
      crossFade: true,
    },
  });
}
