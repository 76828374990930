// const links = document.querySelectorAll("a");
// const tels = links.map((value, index, links) => {
//   //「index番号」が偶数の時だけ2倍にする
//   if (index % 2 !== 0) {
//     return value;
//   } else {
//     return "aaaaaaa";
//   }
// });

// console.log(tels);
