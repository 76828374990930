import Accordion from '../../plugins/accordion.js';
import isMobile from "../../plugins/is-mobile.js";

/*サイドメニュー スマホ時アコーディオン */
if (isMobile(768)) {
  const elements = document.querySelectorAll(".js-accordion");
  for (const element of elements) {
    let accordion = new Accordion(element, {
      //defaultOpenPanels: [0],
      multipleOpen: true,
    });
  }
}


/*サイドメニュー 第二階層用のアコーディオン */
if (!isMobile(768)) {
  const elements2 = document.querySelectorAll(".js-accordion2");
  for (const element2 of elements2) {
    let accordion2 = new Accordion(element2, {
      //defaultOpenPanels: [0],
      multipleOpen: true,
      trigger: ".js-accordion-trigger2",
      panel: ".js-accordion-panel2",
    });
  }
  $('.hoverOpen').hover(function() {
    $(this).find('.js-accordion-trigger2').click();
  });
}


/*サイドメニュー 第三階層用のアコーディオン */
if (!isMobile(768)) {
  const elements3 = document.querySelectorAll(".js-accordion3");
  for (const element3 of elements3) {
    let accordion3 = new Accordion(element3, {
      defaultOpenPanels: [0],
      multipleOpen: true,
      trigger: ".js-accordion-trigger3",
      panel: ".js-accordion-panel3",
    });
  }
  $('.hoverOpen').hover(function() {
    $(this).find('.js-accordion-trigger3').click();
  });
}

/*Q&Aのアコーディオン */

const elements4 = document.querySelectorAll(".js-accordion4");
for (const element4 of elements4) {
  let accordion4 = new Accordion(element4, {
    //defaultOpenPanels: [0],
    multipleOpen: true,
    trigger: ".js-accordion-trigger4",
    panel: ".js-accordion-panel4",
  });
}




/*
if (isMobile(768)) {
  const elements = document.querySelectorAll(".js-accordion");
  for (const element of elements) {
    let accordion2 = new Accordion(element, {
      //defaultOpenPanels: [0],
      multipleOpen: true,
      // onOpen: test,
      // onClose: fuga
    });
  }
}

const elements2 = document.querySelectorAll(".js-accordion2");
for (const element2 of elements2) {
  let accordion2 = new Accordion(element2, {
    //defaultOpenPanels: [0],
    multipleOpen: true,
    // onOpen: test,
    // onClose: fuga
  });
}
*/


// let accordion = new Accordion(".js-accordion2", {
//   defaultOpenPanels: [0],
//   onOpen: hoge,
//   onClose: fuga
// });

// function test(trigger, panel){
//   trigger.style.color = '#f00'
// }
// function fuga(trigger, panel){
//   trigger.style.color = '#000'
  
// }

// accordion.destroy()
// accordion.init()
